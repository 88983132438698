.pointer {
  cursor: pointer;
}
.design-wrapper {
  margin-top: 20px;
  padding: 0px 15px 0px 15px;
}

.rounded-box {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
}

.dark-grey-color {
  color: #747474;
}

.card-heading {
  background-color: black;
  color: white;
  text-align: center;
  padding: 10px 0px;
  border-radius: 6px 6px 0px 0px;
}
.card-image-btn {
  width: 100%;
  height: 200px;
}

.card-image-btn-loader {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*  */

#ReactSimpleImageViewer {
  margin-top: 50px;
  z-index: 999;
}

.react-simple-image-viewer__slide {
  margin-top: -50px;
}

.custom-link {
  color: black;
  cursor: pointer;
}

.custom-link:hover {
  color: #f04756;
  cursor: pointer;
}

.download-icon {
  cursor: pointer;
  text-decoration: none;
  color: grey;
}

.download-icon:hover {
  cursor: pointer;
  text-decoration: none;
  color: black;
}
